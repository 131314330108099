import { graphql, Script } from 'gatsby'
import React,{useState, useEffect} from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import axios from 'axios';
import { Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import Layout from '../components/layout'
import { useMediaQuery } from 'react-responsive'
import useWindowSize from '../hooks/useGatsbyWindowSize'

import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
gsap.config({autoKillThreshold: 1});


const IndexPage = (props) => {

  const { t } = useTranslation();
  var _y = 100;
  const [navStatus, setNavStatus] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [jsonContent, setJsonContent] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();
  const lang = props.pageContext.language === 'en' ? '/en' : '';

  //console.log( 'mobile :' + isMobile );

function toggleNave(){
  //navStatus   ? navOn(): navOff();
  //console.log(navStatus);
  navStatus ? setNavStatus(false) : setNavStatus(true);
  gsap.to(document.body,1,{delay: .2, ease: 'Quad.easeOut', scrollTo:{y: _y, autoKill: false }});

}

function loadJson(url) {

  var _json = url;

  axios.get(_json)
.then(function (response) {

  var _jsonContent =  response.data.data[0].description;
  setJsonContent(_jsonContent);
  setIsLoaded(true);


});
}



useEffect(() => {
  //console.log(props);
  loadJson('https://stage5345.oscarjaenada.com/api/v1/home' + lang);

  navStatus ? _y = 100 : _y = 0;

  setIsMobile(mobile);
  setIsLoaded(true);



},[mobile,navStatus]);



if( isMobile && isLoaded ){
  //console.log(props);
  var image = props.data.photos.edges[0];

return (

  <Layout>

        <div className="main-index">
         <GatsbyImage
          fluid={image.node.childImageSharp.fluid}
           alt={image.node.base.split("-").join(" ").split(".")[0]}
          image={getImage(image.node)}
        />
        </div>

        <div id="content-home">
        <h1 className="center">OSCAR JAENADA</h1>
        <div dangerouslySetInnerHTML={{ __html: jsonContent }} />
        </div>
        <div className="video-outer">
        <video id="video-home" width="100%" height="auto" controls poster="/static/oscar-jaenada-video-poster.jpg">
        <source src="/videos/oj-total.mp4" type="video/mp4"/>
        View the video reel...
      </video>
      </div>

      <div className="footer end-content">
      <p className="legal">&copy; Copyright Oscar Jaenada 2022<br/>
      <span className="grey"><a href="/legal"><Trans>Texto legal</Trans></a></span></p>
      </div>

      <noscript>Oscar Jaenada official website - You need to activate javascript to view the site</noscript>


  <nav role="navigation">
  <div id="menuToggle">
  <input
  type="checkbox"
  onClick={()=>toggleNave() }
  />

  <span></span>
  <span></span>
  <span></span>

  <ul id="menuNav" className="home">
  <li><a className="homeLink activePage" href="/"><Trans>HOME</Trans></a></li>
  <li className="menuNavItem">
  <a href={"/" + props.pageContext.language + "/bio"}>BIO</a>
  </li>
  <li className="menuNavItem">
  <a className="activePage" href={"/" + props.pageContext.language + "/" +t('l_projects')}><Trans>PROYECTOS</Trans></a>
  </li>
  <li className="menuNavItem">
  <a href={"/" + props.pageContext.language + "/" + t('l_contact')}><Trans>CONTACTO</Trans></a>
  </li>
  <li className="menuNavItem small">
  <a className={'lang_en_'+ props.pageContext.language} href="/en">EN</a>  <a className={'lang_es_'+ props.pageContext.language} href="/es">ES</a>
  </li>
  </ul>
  </div>
  </nav>

  <div id="nav_bottom">
  <div id="nav_bottom_inner">
  <div id="logo"><a href="/">OSCAR JAENADA</a></div>


  </div>
  </div>

  </Layout>

);
}else if( !isMobile && isLoaded){

  var image = props.data.photos.edges[1];
  return (

    <Layout>
    <div className="main-index">
     <GatsbyImage
      fluid={image.node.childImageSharp.fluid}
       alt={image.node.base.split("-").join(" ").split(".")[0]}
      image={getImage(image.node)}
    />
    </div>

    <div id="content-home">
    <h1 className="center">OSCAR JAENADA</h1>
    <div dangerouslySetInnerHTML={{ __html: jsonContent }} />
    </div>
    <div className="video-outer">
    <video id="video-home" width="100%" height="auto" controls poster="/static/oscar-jaenada-video-poster-1.jpg">
    <source src="/videos/oj-total.mp4" type="video/mp4"/>
    View the video reel...
  </video>
  </div>

  <div className="footer end-content">
  <p className="legal">&copy; Copyright Oscar Jaenada 2022<br/>
  <span className="grey"><a href="/legal"><Trans>Texto legal</Trans></a></span></p>
  </div>


  <noscript>Oscar Jaenada official website - You need to activate javascript to view the site</noscript>

  <nav role="navigation">
  <div id="menuToggle">
  <input
  type="checkbox"
  onClick={()=>toggleNave() }
  />

  <span></span>
  <span></span>
  <span></span>

  <ul id="menuNav" className="home">
  <li><a className="homeLink activePage" href="/"><Trans>HOME</Trans></a></li>
  <li className="menuNavItem">
  <a href={"/" + props.pageContext.language + "/bio"}>BIO</a>
  </li>
  <li className="menuNavItem">
  <a href={"/" + props.pageContext.language + "/" +t('l_projects')}><Trans>PROYECTOS</Trans></a>
  </li>
  <li className="menuNavItem">
  <a href={"/" + props.pageContext.language + "/" + t('l_contact')}><Trans>CONTACTO</Trans></a>
  </li>
  <li className="menuNavItem small">
  <a className={'lang_en_'+ props.pageContext.language} href="/en">EN</a>  <a className={'lang_es_'+ props.pageContext.language} href="/es">ES</a>
  </li>
  </ul>
  </div>
  </nav>

  <div id="nav_bottom">
  <div id="nav_bottom_inner">
  <div id="logo"><a href="/">OSCAR JAENADA</a></div>


  </div>
  </div>



    </Layout>

  );
}
}

export const Head = ({ location, params, data, pageContext }) => (
  <>
    <title key={'x-0'}>Oscar Jaenada</title>
    <meta
      name="twitter:url"
      content={`https://oscarjaenada${location.pathname}`}
    />
    <meta name="HandheldFriendly" content="true"/>
    <meta name="description" content="Oscar Jaenada, official website" />
    <meta property="og:title" content="Oscar Jaenada" />
    <meta name="author" content="Website by Rémi Blot - remiblot.com"/>
    <meta property="og:type" content="website" />
    <meta property="og:url" content={`https://oscarjaenada.com${location.pathname}`} />
    <meta property="og:image" content="https://oscarjaenada.com/static/oscar-jaenada.jpg" />
    <meta property="og:description" content="Oscar Jaenada, official website" />
    <meta property="og:site_name" content="Oscar Jaenada, official website" />
    <meta property="fb:app_id" content="" />
    <link rel="canonical" href="https://oscarjaenada.com/en" />
    <Script id="faq" id="faq" data-strategy="post-hydrate" type="application/ld+json">
    {`
    {
   "@context":"https://schema.org",
   "@type":"FAQPage",
   "mainEntity":[
      {
         "@type":"Question",
         "name":"Where to find the official site of the spanish actor Oscar Jaenada ?",
         "acceptedAnswer":{
            "@type":"Answer",
            "text":"<p>the domain oscarjaenada.com hosts Oscar Jaenada official website.</p>"
         }
      }
   ]
}
`}
    </Script>
  </>
);


export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    file(relativePath: { eq: "index/OJ_DSC5488-1.jpg" }) {
      id
      root
      relativePath
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 400
        )
      }
    }
    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "index"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  quality: 80
                  height: 1200
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }


  }
`;
